import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Tooltip } from "react-tooltip";

import {
  DashboardTitle,
  DashboardText,
  CardHomeContentBodyAction,
  BodyHeader,
  BodyHeaderInfo,
  ContentHeaderSearch,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row } from "reactstrap";
import Button from "components/Form/Button";
import CampaingTabs from "components/Dashboard/Campaing/Tabs";
import Input from "components/Form/Input";
import "react-datepicker/dist/react-datepicker.css";

import { Load } from "ui/styled";

import styled from "styled-components";
import { parseStrapiImage } from "utils";
import { parseInitialize } from "utils/parsers";
import { ReadAll, SendConfirmationEmail } from "services/users";
import { CoreContext } from "context/CoreContext";
import SuccessToast from "components/Toasts/Success";
import Select from "components/Form/Select";

export default function DashboardCampaing() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { user } = useContext(CoreContext);

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const tabs = [
    {
      title: "Backoffice",
      value: "Backoffice",
      roles: ["Admin Master", "Gestor"],
    },
    { title: "ForBusiness", value: "ForBusiness", roles: ["ForBusiness"] },
  ];

  const filterTab = (item) => {
    return tabs?.[active]?.roles.includes(item?.role?.name);
  };

  const filterName = (item) => {
    if (!nameFilter || nameFilter === "") {
      return true;
    }

    return (
      Object.values(item).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(nameFilter.toLowerCase())
      ).length > 0
    );
  };

  const filterRole = (item) => {
    if (!roleFilter || roleFilter === "") {
      return true;
    }

    return item.role.name === roleFilter;
  };

  const filterStatus = (item) => {
    if (!statusFilter || statusFilter === "") {
      return true;
    }

    const mapper = {
      Confirmado: true,
      Pendente: false,
    };

    return mapper[statusFilter] === item.confirmed;
  };

  const reload = async () => {
    setLoading(true);

    const users = await ReadAll();
    setUsers(users);

    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);

    const users = await ReadAll();

    setUsers(users);

    setLoading(false);
  }, []);

  return (
    <>
      {user?.role?.name === "Admin Master" && (
        <ContainerAuthenticated side={2}>
          {!loading ? null : <Load colored centred />}
          <BodyHeader>
            <BodyHeaderInfo>
              <DashboardTitle small={true}>Usuários</DashboardTitle>
              <DashboardText>
                Convide usuários para fazer a gestão de campanhas.
              </DashboardText>
            </BodyHeaderInfo>
            <CardHomeContentBodyAction>
              <Button
                onClick={() => navigate("dashboard/users/create")}
                primary
              >
                + CONVIDAR USUÁRIOS
              </Button>
            </CardHomeContentBodyAction>
          </BodyHeader>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ContentHeaderSearch>
              <Input
                withSearch
                rounded
                placeholder="Buscar usuários"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </ContentHeaderSearch>

            <div
              style={{
                marginLeft: "20px",
                width: "15%",
                borderRadius: "8px",
              }}
            >
              <Select
                backgroundColor="#FFFFFF"
                labelMargin="0"
                labelPadding="0"
                label="Tipo de permissão"
                placeholder="Tipo de permissão"
                options={[
                  { id: "Admin Master", title: "Admin Master" },
                  { id: "Gestor", title: "Gestor" },
                  { id: "ForBusiness", title: "ForBusiness" },
                ]}
                value={roleFilter}
                onChange={(val) => setRoleFilter(val)}
              />
            </div>

            <div
              style={{
                marginLeft: "20px",
                width: "15%",
                borderRadius: "8px",
              }}
            >
              <Select
                backgroundColor="#FFFFFF"
                labelMargin="0"
                labelPadding="0"
                label="Status"
                placeholder="Status"
                options={[
                  { id: "Confirmado", title: "Confirmado" },
                  { id: "Pendente", title: "Pendente" },
                ]}
                value={statusFilter}
                onChange={(val) => setStatusFilter(val)}
              />
            </div>
          </div>

          <CampaingTabs tabs={tabs} active={active} setActive={setActive} />

          {users
            ?.filter(filterTab)
            ?.filter(filterName)
            ?.filter(filterRole)
            ?.filter(filterStatus)
            ?.map((item) => (
              <Row>
                <CardDashboardUser
                  user={item}
                  setUsers={setUsers}
                  setLoading={setLoading}
                />
              </Row>
            ))}
        </ContainerAuthenticated>
      )}
    </>
  );
}

function CardDashboardUser({ user, setUsers, setLoading }) {
  const { setModal } = useContext(CoreContext);
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const reload = async () => {
    setLoading(true);

    const users = await ReadAll();
    setUsers(users);

    setLoading(false);
  };

  const resendConfirmationEmail = async () => {
    await SendConfirmationEmail({ email: user.email });

    SuccessToast({ message: "Convite reenviado com sucesso" });
  };

  return (
    <>
      <CardHomeContent>
        <CardHomeContentBody>
          <div
            style={{
              display: "flex",
            }}
          >
            <CardDashboardCampaignUserImage
              image={user.image?.url ? parseStrapiImage(user.image?.url) : null}
            >
              <CardDashboardCampaignUserImageText>
                {user.image?.url ? null : parseInitialize(user.name)}
              </CardDashboardCampaignUserImageText>
            </CardDashboardCampaignUserImage>
            <div
              style={{
                width: "200px",
              }}
            >
              <CardHomeContentBodyTitle>{user.name}</CardHomeContentBodyTitle>
              <CardHomeContentBodyText>{user.email}</CardHomeContentBodyText>
            </div>
          </div>
          <div>
            <CardHomeContentBodyTitle>{"Permissão"}</CardHomeContentBodyTitle>
            <CardHomeContentBodyText>{user.role.name}</CardHomeContentBodyText>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <CardHomeContentLabel isSuccess={user.confirmed}>
              <CardHomeContentLabelDecoration isSuccess={user.confirmed} />
              <CardHomeContentLabelText isSuccess={user.confirmed}>
                {user.confirmed ? "Confirmado" : "Pendente"}
              </CardHomeContentLabelText>
            </CardHomeContentLabel>
            <Tooltip style={{ borderRadius: "8px" }} id="edit-user-tooltip" />
            <CardDashboardCampaignEditIcon
              data-tooltip-id="edit-user-tooltip"
              data-tooltip-content="Editar usuário"
              onClick={() => navigate(`dashboard/users/edit/${user.id}`)}
            />
            {!user.confirmed && (
              <>
                <Tooltip style={{ borderRadius: "8px" }} id="resend-tooltip" />
                <CardDashboardCampaignResendIcon
                  data-tooltip-id="resend-tooltip"
                  data-tooltip-content="Reenviar convite"
                  onClick={resendConfirmationEmail}
                />
              </>
            )}
            <Tooltip style={{ borderRadius: "8px" }} id="delete-user-tooltip" />
            <CardDashboardCampaignTrashIcon
              data-tooltip-id="delete-user-tooltip"
              data-tooltip-content="Deletar usuário"
              onClick={() =>
                setModal({ type: "delete-user", item: user, reload })
              }
            />
          </div>
        </CardHomeContentBody>
      </CardHomeContent>
    </>
  );
}

const CardHomeContent = styled.div.attrs({})`
  width: 100%;
  margin: 16px 0 0;
  padding: 16px 24px;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.colors.white};

  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
`;
const CardHomeContentLabel = styled.div.attrs({})`
  padding: 4px 16px 4px 8px;
  background: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.greenLight
      : props.theme.palette.colors.purpleLight};
  border-radius: 32px;
  display: flex;
  align-items: center;
`;
const CardHomeContentLabelDecoration = styled.img.attrs((props) => ({
  src: props.isSuccess ? "/icons/approved.svg" : "/icons/info.svg",
}))`
  width: 13px;
  height: 13px;
  margin: 0 8px 0 0;
`;
export const CardHomeContentLabelText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.green
      : props.theme.palette.colors.blue};
`;
export const CardHomeContentBody = styled.div.attrs({})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CardHomeContentBodyTitle = styled.div.attrs({})`
  font-size: 18px;
  color: ${(props) => props.theme.palette.colors.black};
  margin-left: 20px;
`;
export const CardHomeContentBodyText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.text};
  margin: 8px 0 16px 20px;
`;
export const CardHomeContentBodyActions = styled.div.attrs({})`
  padding: 8px 0 0;
  display: flex;
`;
export const CardDashboardCampaignEditIcon = styled.img.attrs({
  src: "/icons/edit.svg",
  width: 18,
})`
  &:hover {
    cursor: pointer;
  }
`;
export const CardDashboardCampaignResendIcon = styled.img.attrs({
  src: "/icons/resend.svg",
  width: 18,
})`
  &:hover {
    cursor: pointer;
  }
`;
export const CardDashboardCampaignTrashIcon = styled.img.attrs({
  src: "/icons/trash-2.svg",
  width: 16,
})`
  &:hover {
    cursor: pointer;
  }
`;
export const CardDashboardCampaignMoreDetails = styled.div.attrs({})`
  margin-left: 25px;
  margin-right: 25px;

  color: ${(props) => props.theme.palette.secondary.main};
  &:hover {
    cursor: pointer;
  }
`;
export const CardDashboardCampaignUserImage = styled.div.attrs({})`
  width: 56px;
  height: 56px;
  border-radius: 28px;

  background: ${(p) => p.theme.palette.colors.lightprimary}
    url(${(p) => p.image}) no-repeat center center / cover;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardDashboardCampaignUserImageText = styled.div.attrs({})`
  font-size: 24px;
  color: ${(p) => p.theme.palette.colors.text};
`;
