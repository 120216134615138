import styledCmp from "styled-components";

import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";

export const MaterialSelect = styled(Select)(({ theme, ...props }) => ({
  borderRadius: props.rounded ? 32 : 8,
  height: props.type === "textarea" ? (props.small ? 69 : 150) : "auto",
  ...(props.backgroundColor && { backgroundColor: props.backgroundColor }),
}));

export const InputLabelOut = styledCmp.div.attrs({})`
    padding: ${(p) => (p.labelPadding ? p.labelPadding : "24px 0 8px;")}
    color: ${(p) => p.theme.palette.colors.text};
    ${(p) => (p.labelMargin ? `margin: ${p.labelMargin}` : "")}
`;
