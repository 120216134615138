import React, { useContext } from "react";
import { CoreContext } from "context/CoreContext";

export default function CreatorGaleryCard({
  influencer,
  creators,
  campaign,
  reload,
}) {
  const { setModal } = useContext(CoreContext);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        borderRadius: "16px",
        border: "1px solid rgba(204, 196, 216, 1)",
        marginTop: "16px",
        background: "rgba(255, 255, 255, 1)",
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h6
              style={{
                fontSize: "24px",
                fontWeight: 700,
                margin: "0px 0px 4px",
                marginRight: "8px",
              }}
            >
              {influencer.name}
            </h6>
            <p style={{ fontSize: "16px" }}>{influencer.profile}</p>
            <p
              style={{
                fontSize: "14px",
              }}
            >
              {influencer.defense?.length > 60 ? (
                <p>
                  {influencer.defense?.slice(0, 60) + "..."}{" "}
                  <a
                    style={{
                      color: "rgba(83, 57, 227, 1)",
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setModal({
                        type: "detail-creator",
                        item: { influencer },
                      })
                    }
                  >
                    Ler mais
                  </a>
                </p>
              ) : (
                influencer.defense
              )}
            </p>
          </div>
          <img
            src={influencer.imageUrl || "/icons/menu4-off.svg"}
            width={"64px"}
            height={"64px"}
            style={{
              borderRadius: "32px",
              marginRight: "8px",
            }}
          ></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: "1px solid rgba(204, 196, 216, 1)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ marginBottom: "10px", marginTop: "16px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/images/heart.png"}
                  width={"17px"}
                  height={"16px"}
                  style={{ marginRight: "4px" }}
                ></img>
                <p style={{ fontSize: "16px", margin: 0 }}>Seguidores</p>
              </div>
            </div>
            <div style={{ display: "flex", gap: "8px", alignItems: "start" }}>
              {influencer.socialNetworks.instagram && (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "32px",
                      border: "1.07px solid rgba(204, 196, 216, 1)",
                    }}
                    onClick={() =>
                      window.open(influencer?.socialNetworks?.instagram?.link)
                    }
                  >
                    <img
                      src={"/images/insta-grey.png"}
                      width={"16px"}
                      height={"16px"}
                    ></img>
                  </div>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      margin: 0,
                      background:
                        "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                      "-webkit-text-fill-color": "transparent",
                      "-webkit-background-clip": "text",
                    }}
                  >
                    {numberToK(
                      influencer?.socialNetworks?.instagram?.followers
                    )}
                  </p>
                </>
              )}

              {influencer.socialNetworks.tiktok && (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "32px",
                      border: "1.07px solid rgba(204, 196, 216, 1)",
                    }}
                    onClick={() =>
                      window.open(influencer?.socialNetworks?.titkok?.link)
                    }
                  >
                    <img
                      src={"/images/tiktok-grey.png"}
                      width={"16px"}
                      height={"16px"}
                    ></img>
                  </div>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      margin: 0,
                      background:
                        "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                      "-webkit-text-fill-color": "transparent",
                      "-webkit-background-clip": "text",
                    }}
                  >
                    {numberToK(influencer?.socialNetworks?.tiktok?.followers)}
                  </p>
                </>
              )}

              {influencer.socialNetworks.youtube && (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "32px",
                      border: "1.07px solid rgba(204, 196, 216, 1)",
                    }}
                    onClick={() =>
                      window.open(influencer?.socialNetworks?.youtube?.link)
                    }
                  >
                    <img
                      src={"/icons/galery.svg"}
                      width={"16px"}
                      height={"16px"}
                    ></img>
                  </div>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      margin: 0,
                      background:
                        "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                      "-webkit-text-fill-color": "transparent",
                      "-webkit-background-clip": "text",
                    }}
                  >
                    {numberToK(influencer?.socialNetworks?.youtube?.followers)}
                  </p>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              width: "42px",
              height: "42px",
              borderRadius: "32px",
              background: "rgba(244, 244, 244, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setModal({
                type: "delete-creator",
                item: { influencer, creators, campaign },
                reload,
              })
            }
          >
            <img
              src="/icons/creator-trash.svg"
              width={"22px"}
              height={"22px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function numberToK(number) {
  return number >= 1e6
    ? `${Math.floor(number / 1e6)}M`
    : number >= 1e3
    ? `${Math.floor(number / 1e3)}K`
    : number;
}
