import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { CardHomeContentBodyAction, BodyPanel, PanelForm } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import Button from "components/Form/Button";

import CampaingHeaderBread from "components/Dashboard/Campaing/HeaderBread";

import Input, { MaskedInput } from "components/Form/Input";
import { Load } from "ui/styled";
import SuccessToast from "components/Toasts/Success";
import { ReadOne, Update } from "services/users";
import ErrorToast from "components/Toasts/Error";
import Select from "components/Form/Select";
import { isEmail } from "utils/validators";

const roles = {
  Gestor: 6,
  "Admin Master": 5,
  Backoffice: 3,
  ForBusiness: 4,
};

export default function DashboardUsersEdit() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState({});

  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };
  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const init = useCallback(async () => {
    setLoading(true);

    if (!id) {
      return;
    }

    const user = await ReadOne(id);

    setForm({ ...user, role: user.role.name });

    setLoading(false);
    return;
  }, [id]);

  useEffect(() => {
    init();
  }, [init]);

  const action = async () => {
    try {
      if (!valid()) {
        return;
      }

      setSaving(true);

      await Update(
        {
          name: formValue("name"),
          phone: formValue("phone"),
          role: roles[formValue("role")],
        },
        id
      );

      SuccessToast({ message: "Usuário editado com sucesso" });
    } catch (error) {
      ErrorToast({ message: "Erro ao atualizar usuário" });
    } finally {
      setSaving(false);
      history.goBack();
    }
  };

  const valid = () => {
    if (!formValue("name") || !formValue("name").length) {
      ErrorToast({ message: "Preencha o campo: Nome" });

      return false;
    }

    if (
      !formValue("email") ||
      !formValue("email").length ||
      !isEmail(formValue("email"))
    ) {
      ErrorToast({ message: "Preencha o campo: E-mail" });

      return false;
    }

    if (!formValue("phone") || !formValue("phone").length) {
      ErrorToast({ message: "Preencha o campo: Telefone" });

      return false;
    }

    if (!formValue("role") || !formValue("role").length) {
      ErrorToast({ message: "Preencha o campo: Permissão" });

      return false;
    }

    return true;
  };

  return (
    <>
      <ContainerAuthenticated side={2}>
        <CampaingHeaderBread
          title={formValue("name")}
          bread={["Usuários", formValue("name"), "Editar usuário"]}
        >
          {loading ? null : (
            <CardHomeContentBodyAction>
              <Button onClick={() => history.goBack()} outline primary>
                CANCELAR
              </Button>
              <Button loading={saving} onClick={() => action()} primary>
                SALVAR
              </Button>
            </CardHomeContentBodyAction>
          )}
        </CampaingHeaderBread>

        {loading ? (
          <Load colored />
        ) : (
          <BodyPanel>
            <PanelForm>
              <Input
                label={"Nome *"}
                placeholder="Digite o nome do usuário"
                value={formValue("name")}
                onChange={(e) => changeForm(e.target.value, "name")}
              />
              <Input
                label={"E-mail de acesso *"}
                placeholder="Digite o e-mail do usuário"
                value={formValue("email")}
                onChange={(e) => changeForm(e.target.value, "email")}
              />
              <MaskedInput
                mask={"(99) 99999-9999"}
                label={"Telefone de contato *"}
                placeholder="DDD + Número"
                value={formValue("phone")}
                onChange={(e) => changeForm(e.target.value, "phone")}
              />
              <Select
                label={"Permissão *"}
                placeholder="Selecione"
                options={[
                  { id: "Admin Master", title: "Admin Master" },
                  { id: "Gestor", title: "Gestor" },
                  { id: "Backoffice", title: "Backoffice" },
                  { id: "ForBusiness", title: "ForBusiness" },
                ]}
                value={formValue("role")}
                onChange={(val) => changeForm(val, "role")}
              />
              <div
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "16px",
                  border: "1px solid #5339E3",
                }}
              >
                <img src="/icons/info.svg" width={"20px"} height={"20px"}></img>
                <div style={{ marginLeft: "16px" }}>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    Somente usuários com permissão do tipo ADMIN MASTER podem
                    convidar novos usuários. Permissões do tipo GESTOR podem
                    criar e editar campanhas.
                  </p>
                </div>
              </div>
            </PanelForm>
          </BodyPanel>
        )}
      </ContainerAuthenticated>
    </>
  );
}
