import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { PanelForm, BodyPanel } from "./styled";

import { Create, SendConfirmationEmail } from "services/users";

import ContainerAuthenticated from "containers/Authenticated";

import CampaingHeaderBread from "components/Dashboard/Campaing/HeaderBread";
import Input, { MaskedInput } from "components/Form/Input";

import SuccessToast from "components/Toasts/Success";
import Select from "components/Form/Select";
import ErrorToast from "components/Toasts/Error";
import { isEmail } from "utils/validators";

const roles = {
  Gestor: 6,
  "Admin Master": 5,
  Backoffice: 3,
  ForBusiness: 4,
};

export default function DashboardUsersCreate() {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});

  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };
  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const actions = [
    { title: "CANCELAR", outline: true, action: () => back() },
    {
      title: "SALVAR",
      loadable: true,
      action: () => save(),
    },
  ];

  const save = async () => {
    try {
      if (!valid()) {
        return;
      }

      setLoading(true);

      await Create({
        ...form,
        username: form.email,
        password: `${form.email}_B@ck0FFice`,
        role: roles[form.role],
      });

      await SendConfirmationEmail({ email: form.email });

      setLoading(false);

      SuccessToast({ message: "Usuário criado com sucesso" });

      navigate("dashboard/users");
    } catch (error) {
      setLoading(false);

      ErrorToast({ message: "Erro ao criar usuário" });

      navigate("dashboard/users");
    }
  };

  const valid = () => {
    if (!formValue("name") || !formValue("name").length) {
      ErrorToast({ message: "Preencha o campo: Nome" });

      return false;
    }

    if (
      !formValue("email") ||
      !formValue("email").length ||
      !isEmail(formValue("email"))
    ) {
      ErrorToast({ message: "Preencha o campo: E-mail" });

      return false;
    }

    if (!formValue("phone") || !formValue("phone").length) {
      ErrorToast({ message: "Preencha o campo: Telefone" });

      return false;
    }

    if (!formValue("role") || !formValue("role").length) {
      ErrorToast({ message: "Preencha o campo: Permissão" });

      return false;
    }

    return true;
  };

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <ContainerAuthenticated side={2} actions={actions} loading={loading}>
        <CampaingHeaderBread
          title="Usuários"
          bread={["Usuários", "Cadastrar Usuário"]}
        />
        <BodyPanel>
          <PanelForm>
            <Input
              label={"Nome *"}
              placeholder="Digite o nome do usuário"
              value={formValue("name")}
              onChange={(e) => changeForm(e.target.value, "name")}
            />
            <Input
              label={"E-mail de acesso *"}
              placeholder="Digite o e-mail do usuário"
              value={formValue("email")}
              onChange={(e) => changeForm(e.target.value, "email")}
            />
            <MaskedInput
              mask={"(99) 99999-9999"}
              label={"Telefone de contato *"}
              placeholder="DDD + Número"
              value={formValue("phone")}
              onChange={(e) => changeForm(e.target.value, "phone")}
            />
            <Select
              label={"Permissão *"}
              placeholder="Selecione"
              options={[
                { id: "Admin Master", title: "Admin Master" },
                { id: "Gestor", title: "Gestor" },
              ]}
              value={formValue("role")}
              onChange={(val) => changeForm(val, "role")}
            />
            <div
              style={{
                display: "flex",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
                border: "1px solid #5339E3",
              }}
            >
              <img src="/icons/info.svg" width={"20px"} height={"20px"}></img>
              <div style={{ marginLeft: "16px" }}>
                <p style={{ fontSize: "18px", margin: 0 }}>
                  Somente usuários com permissão do tipo ADMIN MASTER podem
                  convidar novos usuários. Permissões do tipo GESTOR podem criar
                  e editar campanhas.
                </p>
              </div>
            </div>
          </PanelForm>
        </BodyPanel>
      </ContainerAuthenticated>
    </>
  );
}
